export default class EventWebPageData {
  public static createEmpty(): EventWebPageData {
    return new EventWebPageData();
  }
  constructor(
    public Id = 0,
    public ApplicationId = 0,
    public EventId = 0,
    public Path = '',
    public EncodedPath = '',
    public IsPublished = false,
    public PublishedDate = new Date(),
    public PublishedVersionId = 0,
    public PreviewVersionId = 0,
    public HidePageFromSearchEngines = false,
    public PreviewGUID  = '',
  ){}
}
